import { g as getElement } from './index-6dad2db9.js';
import './MutationObserverSafe-7a5dacdc.js';
import { d as isElementReady } from './element-d46853d9.js';
import { a as SubscriptionList, B as BehaviorSubject } from './subscription-list.class-9c42aac2.js';

const CTX_KEY = 's-ctx';
const SUBSCRIPTIONS_KEY = 's-subscriptions';
const GET_CONTEXT_RETRY_COUNT = 40;
const GET_CONTEXT_RETRY_INTERVAL = 50;
const UNSUBSCRIBE_TIMEOUT = 2000;
const isElementMatched = (startingElement, currentElement, target) => {
  var _a;
  if (!currentElement) {
    return false;
  }
  if (target) {
    if (typeof target === 'function') {
      return target(currentElement, startingElement);
    }
    else {
      return ((_a = currentElement.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === target.toLowerCase();
    }
  }
  else {
    return true;
  }
};
const findElementFromTarget = (startingElement, target) => {
  let currentElement = startingElement;
  while (currentElement && !isElementMatched(startingElement, currentElement, target)) {
    currentElement = currentElement === null || currentElement === void 0 ? void 0 : currentElement.parentElement;
  }
  return currentElement;
};
const getContext = (element, name, disableTraverse = false) => {
  const ctx = element === null || element === void 0 ? void 0 : element[CTX_KEY];
  if (ctx && ctx instanceof Map && ctx.has(name)) {
    return { context: ctx.get(name), element };
  }
  if (!disableTraverse) {
    const parent = element === null || element === void 0 ? void 0 : element.parentElement;
    if (parent) {
      return getContext(parent, name);
    }
  }
  return undefined;
};
const addContext = (element, name, initialValue) => {
  if (element) {
    !(element[CTX_KEY] && element[CTX_KEY] instanceof Map) && (element[CTX_KEY] = new Map());
    element[CTX_KEY].set(name, new BehaviorSubject(initialValue));
    return element[CTX_KEY].get(name);
  }
};
const onContextChangeMethod = (instance, methodName, ctxKey, options) => {
  var _a;
  const host = getElement(instance);
  const method = instance[methodName];
  /* get SUBSCRIPTIONS and create if it doesn't exist */
  !(host[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (host[SUBSCRIPTIONS_KEY] = new SubscriptionList());
  const subscriptions = host[SUBSCRIPTIONS_KEY];
  /**
   * - if target is defined, subscribe the context in that element
   * - otherwise try to find context in its own or its parents by retrying and then subscribe that context in the first found element
   */
  if (options === null || options === void 0 ? void 0 : options.target) {
    const targetElement = findElementFromTarget(host, options.target);
    /* context is already defined (see connectedCallback method) */
    const context = (_a = getContext(targetElement, ctxKey, true)) === null || _a === void 0 ? void 0 : _a.context;
    context && (subscriptions[ctxKey] = context.subscribe((v, oldValue) => method.call(instance, v, oldValue)));
  }
  else {
    isElementReady(() => getContext(host, ctxKey), GET_CONTEXT_RETRY_COUNT, GET_CONTEXT_RETRY_INTERVAL).then(() => {
      var _a;
      const context = (_a = getContext(host, ctxKey)) === null || _a === void 0 ? void 0 : _a.context;
      context && (subscriptions[ctxKey] = context.subscribe((v, oldValue) => method.call(instance, v, oldValue)));
    });
  }
};
class ContextValue {
  set(value, forced = false) {
    var _a;
    const ctx = getContext(this._cachedElement || this.hostElement, this.ctxKey);
    this._cachedElement = ctx.element;
    (_a = ctx === null || ctx === void 0 ? void 0 : ctx.context) === null || _a === void 0 ? void 0 : _a.next(value, forced);
  }
  get() {
    var _a;
    const ctx = getContext(this._cachedElement || this.hostElement, this.ctxKey);
    this._cachedElement = ctx.element;
    const value = (_a = ctx === null || ctx === void 0 ? void 0 : ctx.context) === null || _a === void 0 ? void 0 : _a.getValue();
    return value;
  }
}
function Context(key) {
  return (proto, propertyKey) => {
    const { connectedCallback } = proto;
    proto.connectedCallback = function () {
      const host = getElement(this);
      const existingCtx = getContext(host, key);
      !existingCtx && addContext(host, key);
      !(this[propertyKey] instanceof ContextValue) && (this[propertyKey] = new ContextValue());
      this[propertyKey].hostElement = host;
      this[propertyKey].ctxKey = key;
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
  };
}
function OnContextChange(key, options) {
  return (proto, methodName) => {
    const { connectedCallback, disconnectedCallback, componentWillLoad, componentDidLoad } = proto;
    const { stage = 'willLoad' } = options || {};
    let timer;
    proto.connectedCallback = function () {
      var _a;
      /** if target is defined, find element and create the context in that element */
      if ((options === null || options === void 0 ? void 0 : options.target) && !timer) {
        const host = getElement(this);
        const targetElement = findElementFromTarget(host, options.target);
        let context = (_a = getContext(targetElement, key, true)) === null || _a === void 0 ? void 0 : _a.context;
        targetElement && !context && (context = addContext(targetElement, key));
      }
      timer && clearTimeout(timer);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    if (stage === 'willLoad') {
      proto.componentWillLoad = function () {
        onContextChangeMethod(this, methodName, key, options);
        return componentWillLoad === null || componentWillLoad === void 0 ? void 0 : componentWillLoad.call(this);
      };
    }
    else if (stage === 'didLoad') {
      proto.componentDidLoad = function () {
        onContextChangeMethod(this, methodName, key, options);
        return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
      };
    }
    proto.disconnectedCallback = function () {
      const host = getElement(this);
      timer = setTimeout(() => {
        const subscriptions = host === null || host === void 0 ? void 0 : host[SUBSCRIPTIONS_KEY];
        subscriptions && subscriptions instanceof SubscriptionList && subscriptions.unsubscribeSafe(key);
      }, UNSUBSCRIBE_TIMEOUT);
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}

export { ContextValue as C, OnContextChange as O, Context as a };
